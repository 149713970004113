import type { IModalData, IToastData } from './ui';

export enum Resource {
    Pornhub = 'pornhub',
    Onlyfans = 'onlyfans',
    Telegram = 'telegram',
    Instagram = 'instagram',
    Facebook = 'facebook',
    Twitter = 'twitter',
    Reddit = 'reddit',
    Discord = 'discord',
    Snapchat = 'snapchat',
    Tiktok = 'tiktok',
    Chaturbate = 'chaturbate',
    Clips4sale = 'clips4sale',
    Fansly = 'fansly',
    Manyvids = 'manyvids',
    Fetlife = 'fetlife',
    Myfreecams = 'myfreecams',
    Loyalfans = 'loyalfans',
    Euroescortgirls = 'euroescortgirls',
    Sextpanther = 'sextpanther',
}

export enum ResourceType {
    Adult = 'adult',
    Social = 'social',
    Personal = 'personal.',
}

export enum Level {
    Critical = 'critical',
    Error = 'error',
    Alert = 'alert',
    Warning = 'warning',
    Success = 'success',
    Info = 'info',
    Debug = 'debug',
};

export type IApiResponse<T> = {
    success: boolean;
    is_redirect: boolean;
    url: string | null;
    data: T;
};

export type IApiActionResponse = {
    action: 'toast' | 'modal' | 'redirect';
    notification: IToastData | IModalData | null;
    redirect: IRedirectResponseData | null;
};

export type IApiMessage = {
    title: string;
    desc: string;
    level: Level;
    type: 'toast' | 'modal' | 'redirect';
    duration?: number;
};

export type IApiData = {
    action: 'redirect' | 'modal' | 'toast';
    toastData?: IToastData;
    modalData?: IModalData;
    redirectData?: IRedirectResponseData;
};

export type IRedirectResponseData = {
    url: string;
};

export type IUserAuthData = {
    id: number;
    username: string;
    avatar_img: string | null;
    initials: string;
    preferred_language: string;
};

export type IResponseMessage = {
    title: string;
    desc: string;
    level: Level;
    redirect_url?: string;
};

export type IUserDashboardData = {
    total_links: number;
};

export type IApiExceptionData = {
    title: string;
    desc: string;
    url?: string;
};

export type IUrlValidationResponse = {
    is_valid: boolean;
    failed_message: string | null;
};

export type IUser = {
    id: number;
    username: string;
    avatar_img: string | null;
    preferred_language: string;
    initials: string;
    profile_url: string;
};

export type IDashboardUser = {
    user: IUser;
    settings: IUserProfileSettings;
};

export type IUserProfileSettings = {
    is_avatar_hidden: boolean;
    is_about_hidden: boolean;
    has_avatar: boolean;
    has_about_text: boolean;
};

export type IUserData = {
    id: number;
    username: string;
    first_name: string;
    second_name: string;
    avatar_img: string;
    profile_url: string;
    about?: string;
    friends: any;
    links: any;
    initials: string;
};

export type IRedirectResponse = {
    url: string;
};

export type IUserLink = {
    resource: Resource;
    title: string;
    url: string;
    type: ResourceType;
    is_valid: boolean | false;
    is_hidden: boolean;
};

export type IUserProfileData = {
    user: IUser | null;
    userLinks: IUserLink[] | null;
    userFriends: IUserData[] | null;
    isAuthenticated: boolean | null;
    isLoading: boolean;
    activeTab: string;
};

export type IUserLinkFormField = {
    label: string;
    title: string;
    url: string;
    regex?: string;
    failed_message: string;
    min_length: number;
    max_length: number;
    type: ResourceType;
};

export type IResource = {
    resource: Resource;
    title: string;
    url?: string | null;
    regex?: string;
    failed_message?: string;
    min_length: number;
    max_lenght: number;
    type: ResourceType;
    address: string;
};

export type IOverviewDashboardData = {
    first_name: string;
    second_name?: string;
    profile_url: string;
};

export type IUserFriend = {
    id: number;
    username: string;
    initials: string;
    profile_url: string;
    avatar_img?: string;
    is_hidden: boolean;
};

export type SaveUserLinkRequest = {
    label: Resource;
    url: string;
};
