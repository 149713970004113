'use client';

import type { ReactNode } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { toast } from 'sonner';
import { NotificationContext } from '@/contexts/notification-context';
import type { IModalData, IToastAction, IToastData } from '@/types/ui';
import { useRouter } from 'next/navigation';
import ConfirmModal from '@/components/custom/modals/ConfirmModal';
import type { IApiActionResponse } from '@/types/api';

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
    const router = useRouter();
    const shownToasts = useRef<{ [key: string]: number }>({});
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);

    const hideModal = useCallback(() => {
        setModalOpen(false);
        setModalContent(null);
    }, []);

    const renderModal = useCallback((modalData: IModalData) => {
        return (
            <ConfirmModal isOpen title={modalData.title} desc={modalData.desc} onClose={hideModal} onConfirm={hideModal} />
        );
    }, [hideModal]);

    const showModal = useCallback((modalData: IModalData) => {
        setModalContent(renderModal(modalData));
        setModalOpen(true);
    }, [renderModal]);

    const showToast = useCallback((toastData: IToastData) => {
        switch (toastData.type) {
            case 'error':
                toast.error(toastData.title, { id: toastData.id, description: toastData.desc, duration: toastData.duration });
                break;
            case 'warning':
                toast.warning(toastData.title, { id: toastData.id, description: toastData.desc, duration: toastData.duration });
                break;
            case 'success':
                toast.success(toastData.title, { id: toastData.id, description: toastData.desc, duration: toastData.duration });
                break;
            case 'loading':
                toast.loading(toastData.title, { id: toastData.id, description: toastData.desc, duration: toastData.duration });
                break;
            case 'message':
                toast.message(toastData.title, { id: toastData.id, description: toastData.desc, duration: toastData.duration });
                break;
            case 'info':
            default:
                toast.info(toastData.title, { id: toastData.id, description: toastData.desc, duration: toastData.duration });
                break;
        }
    }, []);

    const setLoadingToast = useCallback(
        async (promise: Promise<any>, toastData: IToastData) => {
            return toast.promise(promise, {
                loading: toastData.title,
                success: toastData.title,
                error: toastData.title,
                id: toastData.id,
                duration: toastData.duration || 5000,
            });
        },
        [],
    );

    const showToastOnce = useCallback((toastData: IToastData, delayInSeconds: number = 12) => {
        const currentTime = Date.now();

        if (!toastData.id) return;

        const lastShownTime = shownToasts.current[toastData.id] || 0;

        if (currentTime - lastShownTime < delayInSeconds * 1000) {
            return;
        }

        showToast(toastData);

        shownToasts.current[toastData.id] = currentTime;
    }, [showToast]);

    const isToastVisible = useCallback((toastId: string | number | null) => {
        const history = toast.getHistory();
        return history.some(t => t.id === toastId);
    }, []);

    const showSingleToast = useCallback(
        (toastData: IToastData) => {
            if (isToastVisible(toastData.id)) {
                return;
            }
            showToast(toastData);
        },
        [showToast, isToastVisible],
    );

    const hideToast = useCallback((toastId?: number | string) => {
        toast.dismiss(toastId);
    }, []);

    const showToastWithAction = useCallback((toastData: IToastData, toastAction: IToastAction) => {
        const toastOptions = {
            id: toastData.id,
            description: toastData.desc,
            duration: toastData.duration,
            action: {
                label: toastAction.label,
                onClick: toastAction.onClick,
            },
            onDismiss: toastData.onDismiss ? () => toastData.onDismiss!(toastData) : undefined,
            onAutoClose: toastData.onAutoClose ? () => toastData.onAutoClose!(toastData) : undefined,
        };

        toast.message(toastData.title, toastOptions);
    }, []);

    const handleResponse = useCallback((apiResponse: IApiActionResponse) => {
        if (apiResponse.action === 'redirect' && apiResponse.redirect) {
            router.push(`/${apiResponse.redirect.url}`);
        }

        if (apiResponse.notification) {
            if (apiResponse.action === 'toast') {
                showToast(apiResponse.notification as IToastData);
            } else if (apiResponse.action === 'modal') {
                showModal(apiResponse.notification as IModalData);
            }
        }
    }, [router, showModal, showToast]);

    const value = useMemo(
        () => ({ showToast, showToastOnce, showSingleToast, setLoadingToast, showToastWithAction, hideToast, hideModal, showModal, handleResponse }),
        [showToast, showToastOnce, showSingleToast, setLoadingToast, showToastWithAction, hideToast, hideModal, showModal, handleResponse],
    );

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
