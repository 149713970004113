import axios from 'axios';
import { logError } from '@/utils/Logger';
import type { AxiosInstance, AxiosError } from 'axios';

const HTTP: AxiosInstance = axios.create({
    baseURL: 'https://hus.sh/api',
    withCredentials: true,
    timeoutErrorMessage: 'Failed on timeout.',
    responseType: 'json',
    adapter: typeof window === 'undefined' ? 'http' : 'xhr',
    maxRedirects: 2,
    transitional: {
        clarifyTimeoutError: true,
    },
});

HTTP.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError) => {
        if ((error.config && error.config.url !== '/services/logger')) {
            logError(`${error.message} error #${error.code} occurred.`, { request: error.request });
        }

        return Promise.reject(error);
    },
);

export { HTTP };
