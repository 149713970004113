'use client';

import type { ReactNode } from 'react';
import { useCallback, useState, useMemo } from 'react';
import { ModalContext } from '@/contexts/modal-context';

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);

    const showModal = useCallback((component: ReactNode) => {
        setModalContent(component);
        setModalOpen(true);
    }, []);

    const hideModal = useCallback(() => {
        setModalOpen(false);
        setModalContent(null);
    }, []);

    const value = useMemo(() => ({ isModalOpen, showModal, hideModal }), [isModalOpen, showModal, hideModal]);

    return (
        <ModalContext.Provider value={value}>
            {children}
            {isModalOpen && modalContent}
        </ModalContext.Provider>
    );
};
