import { HTTP } from '@/lib/http';
import type { IApiResponse } from '@/types/api';
import { Level } from '@/types/api';

export async function logError(logMessage: string, logContext?: any, userId?: number): Promise<void> {
    log(logMessage, Level.Error, logContext, userId);
}

export async function logWarning(logMessage: string, logContext?: any, userId?: number): Promise<void> {
    log(logMessage, Level.Warning, logContext, userId);
}

export async function logAlert(logMessage: string, logContext?: any, userId?: number): Promise<void> {
    log(logMessage, Level.Alert, logContext, userId);
}

export async function logInfo(logMessage: string, logContext?: any, userId?: number): Promise<void> {
    log(logMessage, Level.Info, logContext, userId);
}

export async function logSuccess(logMessage: string, logContext?: any, userId?: number): Promise<void> {
    log(logMessage, Level.Success, logContext, userId);
}

async function log(logMessage: string, logLevel: Level, logContext?: any, userId?: number): Promise<void> {
    HTTP.post<IApiResponse<void>>('/services/logger', { logMessage, logContext, logLevel, userId });
};
