'use client';

import { useRouter } from 'next/navigation';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { AuthContext } from '@/contexts/auth-context';
import type { IApiResponse, IRedirectResponse, IUser } from '@/types/api';
import { logError } from '@/utils/Logger';
import { HTTP } from '@/lib/http';

export const AuthProvider = ({ children, user: initialUser }: { children: ReactNode; user: IUser | null }) => {
    const router = useRouter();
    const [user, setUser] = useState<IUser | null>(initialUser);

    const login = useCallback((user: IUser) => {
        setUser(user);
        sessionStorage.setItem('hus_session', JSON.stringify(user));
    }, []);

    const logout = useCallback(() => {
        setUser(null);
        sessionStorage.removeItem('hus_session');
        router.push('/');
    }, []);

    const signUp = useCallback(async () => {
        try {
            const response = await HTTP.post<IApiResponse<IRedirectResponse>>('/auth/twitter/');

            if (response.data.success && response.data.data) {
                const url = decodeURIComponent(response.data.data.url);
                window.location.href = url;
            } else {
                logError(`AuthProvider: invalid Twitter auth response received: ${response.data.data}`);
                throw new Error('Invalid Twitter auth response received.');
            }
        } catch (error: any) {
            logError('AuthProvider: failed on signing-in.', error);
            router.push('/error');
        }
    }, []);

    const value = useMemo(() => ({ user, login, logout, signUp }), [user, login, logout, signUp]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
