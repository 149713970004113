import { createContext, useContext } from 'react';
import type { IToastData, IToastAction, IModalData } from '@/types/ui';
import type { IApiActionResponse } from '@/types/api';

type NotificationContextType = {
    showToast: (toastData: IToastData) => void;
    showToastOnce: (toastData: IToastData) => void;
    showSingleToast: (toastData: IToastData) => void;
    setLoadingToast: (promise: Promise<any>, toastData: IToastData) => Promise<any>;
    showToastWithAction: (toastData: IToastData, toastAction: IToastAction) => void;
    hideToast: (toastId?: number | string) => void;
    handleResponse: (response: IApiActionResponse) => void;
    showModal: (response: IModalData) => void;
    hideModal: () => void;
};

export const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
