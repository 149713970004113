'use client';

import type { ReactNode } from 'react';
import { NotificationProvider } from './notification-provider';
import { ModalProvider } from './modal-provider';
import { Toaster } from 'sonner';

export const UIProvider = ({ children }: { children: ReactNode }) => {
    return (
        <NotificationProvider>
            <ModalProvider>
                <Toaster position="top-right" richColors />
                {children}
            </ModalProvider>
        </NotificationProvider>
    );
};
