'use client';

import { AlertDialogHeader, AlertDialogFooter, AlertDialog, AlertDialogContent, AlertDialogTitle, AlertDialogDescription, AlertDialogCancel, AlertDialogAction } from '@/components/ui/alert-dialog';
import { useTranslations } from 'next-intl';

type ConfirmModalProps = {
    title: string;
    desc: string;
    isOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({ title, desc, isOpen, onConfirm, onClose }) => {
    const trans = useTranslations('Dashboard');

    return (
        <AlertDialog open={isOpen} onOpenChange={onClose}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription>{desc}</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={onClose}>{trans('close')}</AlertDialogCancel>
                    <AlertDialogAction onClick={onConfirm}>{trans('confirm')}</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ConfirmModal;
