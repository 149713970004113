import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/front/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/front/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/front/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\",\"latin-ext\",\"cyrillic-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"display\":\"swap\"}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/var/www/front/node_modules/next/font/google/target.css?{\"path\":\"src/components/custom/TextLogo.tsx\",\"import\":\"Jura\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\"],\"display\":\"swap\"}],\"variableName\":\"jura\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/var/www/front/src/components/buttons/mode-toggle.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/src/components/custom/buttons/LoginButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/var/www/front/src/providers/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/var/www/front/src/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UIProvider"] */ "/var/www/front/src/providers/ui-provider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/src/styles/global.css");
